import React from 'react';

import Layout from '../components/layout';
import Nav from '../components/nav';
import Footer from '../components/footer';
import Slogan from '../components/slogan';
import Contact from '../components/contact';
import SEO from '../components/seo';

import '../scss/attorno.scss';

const WaaromAttorno = () => (
  <Layout>
    <SEO
      title="Waarom attorno"
      description=" Op uw feest bakken wij een dozijn verschillende soorten pizza’s,
                waarbij enkele zeer exquisiete, de een al lekkerder dan de
                ander. Ze worden in punten gesneden zodat ieder de grote
                variëteit aan pizza’s kan appreciëren. Ieder eet in ieder geval
                zoveel hij/zij wil. Op vraag bakken wij ook lactose – of
                glutenvrije pizza’s"
    />

    <Nav />

    <header
      className="intro-block py-3 cover-bg"
      style={{
        backgroundImage: 'url(\'/design/img/header/ovens-helikopter.jpg\')',
      }}
    >
      <div className="container">
        <Slogan />
      </div>
    </header>

    <section
      className="pattern-bg pt-9"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container">
        <div className="row eh-grid">
          <div className="col-sm-6 col-md-4">
            <div className="list-item-center">
              <img
                src="/design/img/feature-food1.png"
                alt="Uitgebreid assortiment"
                className="screen"
              />
              <h3>Uitgebreid assortiment</h3>
              <p>
                Op uw feest bakken wij een dozijn verschillende soorten pizza’s,
                waarbij enkele zeer exquisiete, de een al lekkerder dan de
                ander. Ze worden in punten gesneden zodat ieder de grote
                variëteit aan pizza’s kan appreciëren. Ieder eet in ieder geval
                zoveel hij/zij wil. Op vraag bakken wij ook lactose – of
                glutenvrije pizza’s
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <div className="list-item-center">
              <img
                src="/design/img/feature-food6.png"
                alt="Verse en kwaliteitsvolle pizza's"
                className="screen"
              />
              <h3>Vers en kwaliteit</h3>
              <p>
                De hoge kwaliteit van onze pizza’s kunnen wij zonder meer
                garanderen omdat wij ze ter plaatse maken en dit met een dagvers
                bereide deeg en enkel met verse ingrediënten van topkwaliteit.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <div className="list-item-center">
              <img
                src="/design/img/feature-food2.png"
                alt="Mobiel pizzakraam bij u thuis"
                className="screen"
              />
              <h3>Presentatie</h3>
              <p>
                Wij plaatsen de houtoven, als U dat wenst, zo dicht mogelijk bij
                de genodigden. De pizza’s worden versneden en aangeboden aan
                onze mooi uitgestalde buffettafel. Die is voorzien van kartonnen
                bordjes, verse kruiden en oliën, servetten. Het bakken en
                verdelen van de pizza’s neemt een 1,5 à 2 uur in beslag.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <div className="list-item-center">
              <img
                src="/design/img/feature-food5.png"
                alt="Meer dan pizza"
                className="screen"
              />
              <h3>Aanvullend</h3>
              <p>
                Wij kunnen, indien U dat wenst, uw event nog op een meer
                uitgebreide manier verzorgen. Denk hierbij bijvoorbeeld aan het
                voorzien in aperitiefhapjes en desserts. De klant is koning en
                in overleg is veel mogelijk.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <div className="list-item-center">
              <img
                src="/design/img/feature-food4.png"
                alt="Prijs"
                className="screen"
              />
              <h3>Prijzen</h3>
              <p>
                Onze prijs is uiteraard afhankelijk van het aantal deelnemers.
                Prijzen beginnen bij 9 euro per persoon. Ook de afstand tot uw
                locatie heeft een zekere invloed op het eindbedrag.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <div className="list-item-center">
              <img
                src="/design/img/feature-food3.png"
                alt="Niets houdt ons tegen, onze pizzaovens zijn mobiel"
                className="screen"
              />
              <h3>Op diverse locaties</h3>
              <p>
                Onze ovens staan gemonteerd op een zeer handig
                transportplatform. Hierdoor speelt de ondergrond (bv los grind)
                of een eventuele smallere doorgang (wij hebben 75cm breedte
                nodig) geen rol.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Contact />

    <Footer />
  </Layout>
);

export default WaaromAttorno;
